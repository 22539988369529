import React, { useState } from "react";
import { useTheme } from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import Button from "../button";
import SideBar from "../sidebar";
import {
  ContentHeader,
  Container,
  ContentLogo,
  Logo,
  Navigation,
  ContentList,
  List,
  SideBarContainer,
} from "./styles";

import logo from "../../assets/logo-ymk-header.webp";

const Header = ({ backgroundColor }) => {
  const theme = useTheme();

  const [sideBarShow, setSideBarShow] = useState(false);

  const navigate = useNavigate();

  const handleNavClick = (event, path) => {
    event.preventDefault();

    if (window.location.pathname !== path) {
      navigate(path);
    } else {
      const sectionId = event.target.getAttribute("href");
      const section = document.querySelector(sectionId);
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const showSideBar = () => {
    setSideBarShow(!sideBarShow);
  };

  return (
    <ContentHeader style={{ backgroundColor: backgroundColor }}>
      <Container>
        <NavLink to={"/"}>
          <ContentLogo>
            <Logo alt="logo_ymk" src={logo} />
          </ContentLogo>
        </NavLink>
        <Navigation>
          <SideBarContainer>
            <FaBars onClick={showSideBar} color="white" />
            {sideBarShow && <SideBar active={setSideBarShow} />}
          </SideBarContainer>
          <ContentList>
            <List>
              <NavLink
                to={"/quem-somos"}
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  color: theme.colors.white,
                  fontFamily: `${theme.typography.title}, sans-serif`,
                  fontSize: "16px",
                }}
              >
                Quem Somos
              </NavLink>
            </List>

            <List
              href="#negocios"
              onClick={(event) => handleNavClick(event, "/")}
            >
              Unidades de Negócios
            </List>
            <List href="#cases" onClick={(event) => handleNavClick(event, "/")}>
              Cases
            </List>
            <List
              href="#contato"
              onClick={(event) => handleNavClick(event, "/")}
            >
              Contato
            </List>
            <List>
              <Button
                width={170}
                color={theme.colors.subtitle_logos}
                background={theme.colors.white}
              >
                Área do investidor
              </Button>
            </List>
          </ContentList>
        </Navigation>
      </Container>
    </ContentHeader>
  );
};

export default Header;
