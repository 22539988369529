import React from "react";

import {
  Container,
  Content,
  TitlePortfolio,
  ContentPortfolio,
  Title,
  ContainerContent,
  ContainerDescription,
  Services,
  ContainerLogo,
  Logo,
  Description,
  ContentTitle,
  Companies,
  BackgroundImage,
} from "./styles";

const PortfolioYMK = ({ companies: companyList }) => {
  return (
    <Container id="negocios">
      <Content>
        <TitlePortfolio>Unidades de Negócios</TitlePortfolio>
      </Content>
      <ContentPortfolio>
        {companyList.map((company) => (
          <Companies key={company.id}>
            <ContentTitle className={`content-${company.id}`}>
              <Title>{company.title}</Title>
            </ContentTitle>
            <ContainerContent
              style={{ height: company.height }}
              className={`content-${company.id}`}
            >
              <BackgroundImage className={`content-${company.id}`} />

              <ContainerDescription>
                {company.logo.map((logo, logoIndex) => (
                  <Services key={logoIndex} className={`content-${company.id}`}>
                    <ContainerLogo>
                      <a
                        href={logo.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Logo
                          src={logo.image}
                          alt={`logo_empresa_${logoIndex + 1}_${company.title}`}
                        />
                      </a>
                    </ContainerLogo>
                    <Description className={`content-${company.id}`}>
                      {company.description[logoIndex]}
                      {Array.isArray(company.subtitle) &&
                        company.subtitle.map((subtitle, subtitleIndex) => (
                          <React.Fragment key={subtitleIndex}>
                            {subtitle}
                          </React.Fragment>
                        ))}
                    </Description>
                  </Services>
                ))}
              </ContainerDescription>
            </ContainerContent>
          </Companies>
        ))}
      </ContentPortfolio>
    </Container>
  );
};

export default PortfolioYMK;
