import React, { useState, useEffect } from "react";

import {
  ContainerMultiplataform,
  ContentText,
  StyledDescription,
  TextMultiplataform,
  TextRemaining,
  Title,
  Video,
  VideoOverlay,
} from "./styles";

import { useTheme } from "styled-components";

import Header from "../../components/header";
import PortfolioYMK from "../../components/portfolio";
import YMKnumbers from "../../components/ymkNumbers";
import Footer from "../../components/footer";

import urblar from "../../assets/logotipo_urblar.webp";
import ymk_realty from "../../assets/logo_ymk_realty.webp";
import estoque_imovel from "../../assets/estoqueimoveis.webp";
import ymk_corp from "../../assets/logo_ymk_corp.webp";
import imovelpra from "../../assets/logo_imovelpra.webp";
import realstate from "../../assets/logo_realestate.webp";
import ymk_land from "../../assets/logo_ymk_land.webp";
import ymk_capital from "../../assets/ymk capital.webp";
import rai from "../../assets/logo_rai_branco.webp";
import investimov from "../../assets/logo_investimov.webp";
import ymkagro from "../../assets/ymk agro.webp";
import credimov from "../../assets/logo-credimov.webp";
import video from "../../assets/video-home.mp4";

const Home = () => {
  const company = [
    {
      id: 1,
      title: "Imóveis",
      logo: [
        { image: urblar, link: "https://www.urblar.com.br/" },
        { image: ymk_realty, link: "https://www.ymkrealty.com.br/" },
        { image: estoque_imovel, link: "https://www.estoqueimovel.com.br/" },
        { image: ymk_corp, link: "https://www.ymkcorp.com.br/" },
        { image: imovelpra, link: "https://www.imovelpra.com.br/" },
        { image: realstate, link: "https://realestate4me.com.br/" },
      ],
      description: [
        "Imóveis ecônomicos em todo Brasil",
        "Imóveis em lançamento de mais de 400 Incorporadoras",
        "Ponta de estoque de imóveis novos e prontos de mais de 700 incorporas e construtoras",
        "Salas comerciais, lojas, lajes comerciais e galpões",
        "Os melhores imóveis de terceiros, totalmente selecionados",
        "Imóveis de 267 Incorporadoras nos EUA, Portugal e Espanha",
      ],
    },
    {
      id: 2,
      title: "Corporate",
      logo: [
        { image: ymk_land, link: "https://www.ymkland.com.br/" },
        { image: ymk_capital, link: "#" },
        { image: rai, link: "#" },
      ],
      description: [
        "Terrenos para incorporação, varejo, BTS e logística",
        "Operações de mercado de capitais e mercado imobiliário",
        "Ativos imobiliários de grande porte para investimentos",
      ],
    },
    {
      id: 3,
      title: "Private",
      logo: [{ image: investimov, link: "https://investimov.com.br/" }],
      description: [
        <Title>
          O maior portfólio de investimentos imobiliários do mercado
        </Title>,
      ],
      subtitle: [
        <StyledDescription>Oportunidades Off Market</StyledDescription>,
        <StyledDescription>Oportunidades Prioritárias</StyledDescription>,
        <StyledDescription>Block deals</StyledDescription>,
      ],
    },
    {
      id: 4,
      title: "Crédito",
      logo: [{ image: credimov, link: "https://credimov.com.br/" }],
      description: [<Title>A casa do crédito imobiliário</Title>],
      subtitle: [
        <StyledDescription style={{ fontWeight: "500", fontSize: 18 }}>
          Principais instituições financeiras
        </StyledDescription>,
        <StyledDescription>Financiamento</StyledDescription>,
        <StyledDescription>CGI</StyledDescription>,
        <StyledDescription>Consórcio de imóveis</StyledDescription>,
        <StyledDescription>Consórcio Contemplado</StyledDescription>,
      ],
    },
    {
      id: 5,
      title: "Agro",
      logo: [{ image: ymkagro, link: "#" }],
      description: [<Title>O maior porfólio de fazendas do Brasil</Title>],
      subtitle: [
        <StyledDescription>
          Certificação de Crédito de Carbono BTS Sillos
        </StyledDescription>,
      ],
    },
  ];

  const theme = useTheme();

  const [shouldHeaderHaveBackground, setShouldHeaderHaveBackground] =
    useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const threshold = 350;

    setShouldHeaderHaveBackground(scrollPosition > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main>
      <Video autoPlay loop muted>
        <source src={video} />
      </Video>

      <VideoOverlay />
      <Header
        backgroundColor={
          shouldHeaderHaveBackground ? theme.colors.primary : "transparent"
        }
      />
      <ContainerMultiplataform>
        <ContentText>
          <TextMultiplataform>Multiplataforma 360º</TextMultiplataform>
          <TextRemaining>de negócios imobiliários</TextRemaining>
        </ContentText>
      </ContainerMultiplataform>
      <YMKnumbers />
      <PortfolioYMK companies={company} />
      <Footer />
    </main>
  );
};

export default Home;
