import styled from "styled-components";

export const StyledDescription = styled.label``;

export const Title = styled.h1`
  font-size: 18px;
`;

export const ContainerMultiplataform = styled.div`
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }

  @media (max-width: 485px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 485px) {
    width: 100%;
    text-align: center;
  }
`;

export const TextMultiplataform = styled.label`
  font-size: 57px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;

  @media (max-width: 485px) {
    font-size: 47px;
  }
  @media (max-width: 445px) {
    font-size: 42px;
  }
  @media (max-width: 370px) {
    font-size: 32px;
  }
`;

export const TextRemaining = styled.label`
  font-size: 52px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-weight: 400;
  @media (max-width: 485px) {
    font-size: 42px;
  }
  @media (max-width: 445px) {
    font-size: 37px;
  }
  @media (max-width: 370px) {
    font-size: 27px;
  }
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  object-fit: cover;
  z-index: -1;
`;

export const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  z-index: 0;
  border-bottom: 10px solid ${({ theme }) => theme.colors.primary};
`;
