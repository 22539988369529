import React from "react";

import { ButtonDefault } from "./styles";

const Button = ({ children, color, background, width, onClick, type }) => {
  return (
    <ButtonDefault
      style={{ color: color, background: background, width: width }}
      onClick={onClick}
      type={type}
    >
      {children}
    </ButtonDefault>
  );
};

export default Button;
