import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";
import { useState } from "react";

import { FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp, BsFacebook, BsInstagram } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { AiFillLinkedin } from "react-icons/ai";
import logo from "../../assets/logo_ymk_grupo.webp";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";
import Button from "../button";
import InputMask from "react-input-mask";
import axios from "axios";

import {
  Container,
  Content,
  ContentLogo,
  ContentContact,
  ContentSocial,
  ContentButtons,
  ContentFooter,
  Copright,
  Developer,
  ContainerFooter,
  ContainerInformations,
  Link,
  Logo,
  Contact,
  DataInput,
  TitleContact,
  ContactCompany,
} from "./styles";

import { Input } from "../input/styles";

const Footer = () => {
  const theme = useTheme();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };

  const {
    handleSubmit: handleSubmitContact,
    control: controlContact,
    formState: { errors: errorsContact },
    register: registerContact,
    reset: resetContact,
  } = useForm();

  const {
    handleSubmit: handleSubmitCompany,
    control: controlCompany,
    formState: { errors: errorsCompany },
    register: registerCompany,
    reset: resetCompany,
  } = useForm();

  const useModal = () => {
    const [openModal, setOpenModal] = useState(null);
    const handleOpen = (modalName) => setOpenModal(modalName);
    const handleClose = () => setOpenModal(null);
    return { openModal, handleOpen, handleClose };
  };

  const { openModal, handleOpen, handleClose } = useModal();

  const baseURL = "https://ymk.com.br/backend";

  const onSubmit = (data) => {
    axios
      .post(`${baseURL}/email.php`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setOpenSuccess(true);
          setOpenError(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setOpenSuccess(false);
        setOpenError(true);
      })
      .finally(() => {
        resetContact();
        console.log(data);
      });
  };

  const onSubmitCompany = (data) => {
    axios
      .post(`${baseURL}/email.php`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setOpenSuccess(true);
          setOpenError(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setOpenSuccess(false);
        setOpenError(true);
      })
      .finally(() => {
        resetCompany();
        console.log(data);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1140px",
    height: 800,
    bgcolor: theme.colors.background_modal,
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 1160px)": {
      width: "95%",
    },
    "@media(max-width: 425px)": {
      p: 2,
    },
  };

  return (
    <Container id="contato">
      <Content>
        <ContentLogo>
          <Logo src={logo} alt="logo_ymk" />
        </ContentLogo>

        <ContentContact>
          <ContainerInformations>
            <FaPhoneAlt color={theme.colors.primary} size={20} />
            <Link href="" onClick={(event) => event.preventDefault()}>
              (11) 3995-7808
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <BsWhatsapp color={theme.colors.primary} size={20} />
            <Link href="https://wa.me/5511987654321" target="_blank">
              (11) 98765-4321
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <IoMdMail color={theme.colors.primary} size={20} />
            <Link href="mailto: contato@ymk.com.br" target="_blank">
              contato@ymk.com.br
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <FaLocationDot color={theme.colors.primary} size={20} />
            <Link
              href="https://maps.app.goo.gl/pECPZp9AYsa99cnw8"
              target="_blank"
            >
              Praça Santo Agostinho, 70
              <br />
              Aclimação - São Paulo - SP
              <br />
              CEP 01533-070
            </Link>
          </ContainerInformations>
        </ContentContact>

        <ContentSocial>
          <ContainerInformations>
            <BsInstagram color={theme.colors.primary} size={20} />
            <Link href="https://www.instagram.com/grupoymk/" target="_blank">
              grupoymk
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <AiFillLinkedin color={theme.colors.primary} size={20} />
            <Link
              href="https://www.linkedin.com/company/grupoymk/"
              target="_blank"
            >
              grupoymk
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <BsFacebook color={theme.colors.primary} size={20} />
            <Link href="https://www.facebook.com/ymk.com.br" target="_blank">
              grupoymk
            </Link>
          </ContainerInformations>
        </ContentSocial>

        <ContentButtons>
          <Button
            color={theme.colors.white}
            background={theme.colors.primary}
            width={"100%"}
            onClick={() => handleOpen("associate")}
          >
            SEJA NOSSO PARCEIRO OFICIAL
          </Button>
          <Button
            color={theme.colors.white}
            background={theme.colors.primary}
            width={"100%"}
            onClick={() => handleOpen("contact")}
          >
            ENTRE EM CONTATO
          </Button>

          <Modal open={openModal === "associate"} onClose={handleClose}>
            <Box sx={style}>
              <ContactCompany onSubmit={handleSubmitCompany(onSubmitCompany)}>
                <TitleContact>SEJA NOSSO PARCEIRO OFICIAL</TitleContact>
                <DataInput>
                  <Input
                    placeholder="Nome"
                    control={controlCompany}
                    {...registerCompany("name", {
                      required: "Preenchimento Obrigatório!",
                    })}
                  />
                </DataInput>
                {errorsCompany.name && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsCompany.name.message}
                  </span>
                )}
                <DataInput>
                  <Input
                    placeholder="Empresa"
                    control={controlCompany}
                    {...registerCompany("company", {
                      required: "Preenchimento Obrigatório!",
                    })}
                  />
                </DataInput>
                {errorsCompany.company && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsCompany.company.message}
                  </span>
                )}
                <DataInput>
                  <Input
                    placeholder="E-mail"
                    control={controlCompany}
                    {...registerCompany("email", {
                      required: "Preenchimento Obrigatório!",
                    })}
                  />
                </DataInput>
                {errorsCompany.email && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsCompany.email.message}
                  </span>
                )}

                <DataInput>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    control={controlCompany}
                    {...registerCompany("phone", {
                      required: "Preenchimento Obrigatório!",
                    })}
                    style={{
                      width: "100%",
                      height: "56px",
                      backgroundColor: theme.colors.white,
                      border: "none",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                    }}
                  />
                </DataInput>
                {errorsCompany.phone && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsCompany.phone.message}
                  </span>
                )}

                <DataInput className="message">
                  <Input
                    placeholder="Mensagem"
                    control={controlCompany}
                    {...registerCompany("message", {
                      required: "Preenchimento Obrigatório!",
                    })}
                  />
                </DataInput>
                {errorsCompany.message && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsCompany.message.message}
                  </span>
                )}

                <Button
                  background={theme.colors.primary}
                  color={theme.colors.white}
                  type="submit"
                >
                  Enviar
                </Button>
              </ContactCompany>
              <Snackbar
                open={openSuccess}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="success" sx={{ width: "100%" }}>
                  Formulário enviado com sucesso!
                </Alert>
              </Snackbar>
              <Snackbar
                open={openError}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  Erro no envio do formulário!
                </Alert>
              </Snackbar>
            </Box>
          </Modal>

          <Modal open={openModal === "contact"} onClose={handleClose}>
            <Box sx={style}>
              <Contact onSubmit={handleSubmitContact(onSubmit)}>
                <TitleContact>ENTRE EM CONTATO</TitleContact>

                <DataInput>
                  <Input
                    placeholder="Nome"
                    control={controlContact}
                    {...registerContact("name", {
                      required: "Preenchimento Obrigatório!",
                    })}
                  />
                </DataInput>
                {errorsContact.name && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsContact.name.message}
                  </span>
                )}

                <DataInput>
                  <Input
                    placeholder="E-mail"
                    control={controlContact}
                    {...registerContact("email", {
                      required: "Preenchimento Obrigatório!",
                    })}
                  />
                </DataInput>
                {errorsContact.email && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsContact.email.message}
                  </span>
                )}

                <DataInput>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    control={controlContact}
                    {...registerContact("phone", {
                      required: "Preenchimento Obrigatório!",
                    })}
                    style={{
                      width: "100%",
                      height: "56px",
                      backgroundColor: theme.colors.white,
                      border: "none",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                    }}
                  />
                </DataInput>
                {errorsContact.phone && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsContact.phone.message}
                  </span>
                )}

                <DataInput className="message">
                  <Input
                    placeholder="Mensagem"
                    control={controlContact}
                    {...registerContact("message", {
                      required: "Preenchimento Obrigatório!",
                    })}
                  />
                </DataInput>
                {errorsContact.message && (
                  <span
                    style={{
                      color: "red",
                      fontFamily: theme.typography.title,
                      fontSize: "13px",
                    }}
                  >
                    {errorsContact.message.message}
                  </span>
                )}

                <Button
                  background={theme.colors.primary}
                  color={theme.colors.white}
                  type="submit"
                >
                  Enviar
                </Button>
              </Contact>

              <Snackbar
                open={openSuccess}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="success" sx={{ width: "100%" }}>
                  Formulário enviado com sucesso!
                </Alert>
              </Snackbar>
              <Snackbar
                open={openError}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  Erro no envio do formulário!
                </Alert>
              </Snackbar>
            </Box>
          </Modal>
        </ContentButtons>
      </Content>

      <ContentFooter>
        <ContainerFooter>
          <Copright>
            &copy; {new Date().getFullYear()} Grupo YMK | TODOS OS DIREITOS
            RESERVADOS
          </Copright>

          <Developer>
            Desenvolvido por <strong>YPB MARKETING DIGITAL</strong>
          </Developer>
        </ContainerFooter>
      </ContentFooter>
    </Container>
  );
};

export default Footer;
