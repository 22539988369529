import { NavLink } from "react-router-dom";

import { FaTimes } from "react-icons/fa";

import { Container, Content } from "./styles";

import SidebarItem from "./sideBarItem";

const SideBar = ({ active }) => {
  const closeSideBar = () => {
    active(false);
  };

  return (
    <Container sidebar={active ? "true" : "false"}>
      <FaTimes onClick={closeSideBar} />
      <Content>
        <NavLink to={"/quem-somos"} style={{ textDecoration: "none" }}>
          <SidebarItem Text="Quem somos" />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text="Unidades de Negócios" />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text="Cases" />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text="Contato" />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text="Área do Investidor" />
        </NavLink>
      </Content>
    </Container>
  );
};

export default SideBar;
